<template lang="pug">
.sidebar-input-wrapper
  .d-flex.flex-wrap.feedback-style-container
    .feedback-style-icon(v-for="t in types" :class="{'feedback-style-icon-selected': selected === t}" @click="update(t)")
      img(:src="require(`@/assets/editor/svg/feedback-styles/${t}.svg`)")
      .style-name.font-size-0--75.mt-2 {{ $t(`feedbackStyles.${t}`) }}
</template>
<script>
  import { mapState, mapMutations } from 'vuex';

  export default {
    props: {
      type: { type: String },
    },

    data() {
      return {
        types: ['stars', 'smiley', 'yesno'],
      };
    },

    computed: {
      ...mapState(['selectedElement']),
      selected() {
        return this.selectedElement.data.displayType || 'stars';
      },
    },

    methods: {
      ...mapMutations(['updateData']),

      update(t) {
        this.updateData({ property: 'selectedElement.data.displayType', value: t });
        window.om.bus.$emit('generateCss');
      },
    },
  };
</script>

<style lang="sass">
  .feedback-style-container
    justify-content: space-between

    .feedback-style-icon
      cursor: pointer
      width: 33%
      margin-top: 0.5rem
      margin-bottom: 0.5rem
      text-align: center

      img
        margin: auto
        border: 1px solid #C8CDD7
        border-radius: 0.1875rem
        padding: 0.375rem

      .style-name
        text-align: center

      &-selected
        img
          background: #F4F8FB
          border-color: var(--brand-primary-color)
</style>
